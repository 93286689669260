import Image from 'next/image'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { OperatorLogosStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { getStoryBlokImageDimensions } from '@electro/consumersite/src/storyblok/helpers/getStoryBlokImageDimensions'

interface OperatorLogosProps {
  blok: OperatorLogosStoryblok
}

const styles = {
  root: 'bg-gradient-to-r from-secondary/40 to-secondary/10 skew-y-2 py-12 mb-24 pointer-events-none select-none',
  gallery: {
    root: tw(
      '-skew-y-2 max-w-6xl mx-auto px-8',
      'grid grid-cols-2 md:grid-cols-5',
      'gap-8 [&_img]:justify-self-center',
    ),
  },
}

export const OperatorLogos = ({ blok }: OperatorLogosProps) => (
  <div data-testid="operator-logos" className={styles.root}>
    <div className={styles.gallery.root}>
      {blok.logos.slice(0, 10).map((logo) => {
        const { width, height } = getStoryBlokImageDimensions(logo.filename)
        // retina images are twice the size we need them to be here
        let dimensions = {
          width: width / 2,
          height: height / 2,
        }
        // svgs are full size
        if (logo.filename.includes('.svg')) {
          dimensions = {
            width,
            height,
          }
        }
        return (
          <Image
            src={getCmsAssetUrl(logo.filename)}
            width={dimensions.width}
            height={dimensions.height}
            alt={logo.alt}
            key={logo.id}
          />
        )
      })}
    </div>
  </div>
)
