import { render } from '@electro/consumersite/src/storyblok/helpers/storyBlokRichTextRenderer'

import { RichTextStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { tw } from '@electro/shared/utils/tailwind-merge'

interface RichTextProps {
  blok: RichTextStoryblok
}

const styles = {
  largeHeadlines: '[&_h2]:text-primary [&_h2]:text-xl md:[&_h2]:text-3xl',
  noTextPadding: '[&_*]:m-0',
  bullet: {
    default:
      '[&_ul]:list-disc [&_ul]:list-outside [&_ul]:pl-6 [&_li]:relative [&_li]:list-item [&_li]:text-white',
    star: tw(
      '[&_ul]:pl-6',
      '[&_ul>li:last-child]:pb-6',
      // 1st level nested list item styles
      '[&_li]:list-item [&_li]:relative',
      '[&_li]:before:w-5 [&_li]:before:h-5 [&_li]:before:block',
      '[&_li]:before:absolute [&_li]:before:-left-6 [&_li]:before:top-0.5',
      '[&_li]:before:bg-list-star [&_li]:before:bg-cover',
      // 2nd level nested list styles
      '[&_ul>li>ul_li:last-child]:pb-0',
      '[&_ul>li>ul_li]:before:w-3 [&_ul>li>ul_li]:before:h-3',
      '[&_ul>li>ul_li]:before:top-1.5 [&_ul>li>ul_li]:before:-left-5',
    ),
  },
}

export const RichText = ({ blok }: RichTextProps) => (
  <section
    className={tw({
      [styles.largeHeadlines]: blok.largeHeadlines,
      [styles.noTextPadding]: blok.noTextPadding,
      [styles.bullet[blok.bulletStyle]]: true,
    })}
    data-testid="rich-text-blok"
  >
    {render(blok.body)}
  </section>
)
