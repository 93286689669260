export const GooglePlaySvg = ({ className }: { className: string }) => (
  <svg
    aria-label="Apple Icon"
    className={className}
    fill="none"
    viewBox="0 0 25 26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      className="fill-current"
      d="M1.15639 0.966479C1.03827 1.23398 0.973022 1.56174 0.973022 1.943V24.059C0.973022 24.4411 1.03835 24.7689 1.15661 25.0363L13.1905 13.0006L1.15639 0.966479ZM1.85161 25.7555C2.29766 25.9464 2.87953 25.8858 3.51402 25.526L17.6711 17.4812L13.8976 13.7077L1.85161 25.7555ZM18.5736 16.9695L23.015 14.446C24.412 13.651 24.412 12.352 23.015 11.558L18.5716 9.03312L14.6047 13.0006L18.5736 16.9695ZM17.6698 8.52071L3.51402 0.477006C2.87949 0.116428 2.29759 0.0562648 1.85153 0.247403L13.8976 12.2935L17.6698 8.52071Z"
    />
  </svg>
)
