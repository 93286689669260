export const AppleSvg = ({ className }: { className: string }) => (
  <svg
    aria-label="Apple Icon"
    className={className}
    fill="none"
    viewBox="0 0 19 23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill-current"
      d="M15.769 12.3008C15.7907 10.6198 16.6934 9.02922 18.1256 8.14876C17.2221 6.85844 15.7088 6.04034 14.1344 5.99108C12.4552 5.81482 10.8272 6.99591 9.97149 6.99591C9.09922 6.99591 7.78172 6.00858 6.36299 6.03777C4.51374 6.09751 2.78977 7.1489 1.89007 8.76564C-0.0439277 12.1141 1.39866 17.0351 3.25127 19.7417C4.17817 21.0671 5.26145 22.5475 6.6789 22.495C8.06596 22.4375 8.584 21.6105 10.2583 21.6105C11.9171 21.6105 12.4031 22.495 13.8493 22.4616C15.3377 22.4375 16.2754 21.1304 17.1698 19.7925C17.8358 18.8481 18.3483 17.8044 18.6882 16.7C16.9391 15.9602 15.771 14.2 15.769 12.3008Z"
    />
    <path
      className="fill-current"
      d="M13.0373 4.21108C13.8489 3.23687 14.2487 1.98469 14.1518 0.720459C12.912 0.850679 11.7668 1.44324 10.9443 2.38007C10.14 3.2954 9.72143 4.52555 9.80056 5.74146C11.0408 5.75423 12.2601 5.17773 13.0373 4.21108Z"
    />
  </svg>
)
