import SbEditable from '@electro/consumersite/src/storyblok/util/sb-editable-workaround'
import { ComponentDictionaryType } from '@electro/consumersite/types/storyblok'
import * as Sentry from '@sentry/nextjs'
import {
  PageLayout,
  CentralPositioningSection,
  HowItWorks,
  FaqsSection,
  Faqs,
  Faq,
  VideoBanner,
  PageTitle,
  RichText,
  OperatorGrid,
  OperatorCell,
  PostLayout,
  FeatureImage,
  PostImage,
  PostContent,
  InfoBox,
  HeroCommunityPost,
  PostVideo,
  Chart,
  ColumnLayout,
  ContentGroup,
  BigText,
  CtaButton,
  AnchoredHeading,
  RichTextInfoBox,
  Embed,
  VideoPlayer,
  AnimatedImage,
  Table,
  Card,
} from '@electro/consumersite/src/storyblok/bloks'

import {
  HeroV2,
  OperatorLogos,
  Features,
  OctoEcosystem,
  BorderedImagesTop,
  BorderedImagesBottom,
  PartnershipsHero,
  AppScreensV2,
  LargeTextHeadline,
  Pricing,
  FleetsInfo,
  LandingPageHero,
  BackgroundBlock,
  LandingPageInfoBlock,
  ColumnImageLayout,
  BorderedImage,
  TableOfContents,
} from '@electro/consumersite/src/storyblok/bloksV2'

interface DynamicComponentProps {
  blok: any
  meta?: {
    lastPublished: string
  }
}

export const DynamicComponent = ({ blok, meta }: DynamicComponentProps) => {
  const Components: ComponentDictionaryType = {
    page: PageLayout,
    productPositioning: CentralPositioningSection,
    productHowItWorks: HowItWorks,
    frequentlyAskedQuestionsSection: FaqsSection,
    videoBanner: VideoBanner,
    faqs: Faqs,
    faq: Faq,
    pageTitle: PageTitle,
    richText: RichText,
    operatorGrid: OperatorGrid,
    operator: OperatorCell,
    post: PostLayout,
    featureImage: FeatureImage,
    postImage: PostImage,
    postContent: PostContent,
    infoBox: InfoBox,
    heroCommunityPost: HeroCommunityPost,
    postVideo: PostVideo,
    videoPlayer: VideoPlayer,
    chart: Chart,
    columnLayout: ColumnLayout,
    contentGroup: ContentGroup,
    bigText: BigText,
    ctaButton: CtaButton,
    anchoredHeading: AnchoredHeading,
    richTextInfoBox: RichTextInfoBox,
    embed: Embed,
    HeroV2,
    OperatorLogos,
    Features,
    OctoEcosystem,
    PartnershipsHero,
    AppScreensV2,
    LargeTextHeadline,
    Pricing,
    FleetsInfo,
    homepageBorderedImagesTop: BorderedImagesTop,
    homepageBorderedImagesBottom: BorderedImagesBottom,
    AnimatedImage,
    StatsHero: LandingPageHero,
    BackgroundBlock,
    LandingPageInfoBlock,
    ColumnImageLayout,
    Card,
    BorderedImage,
    Table,
    TableOfContents,
  }
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    return (
      <SbEditable content={blok}>
        <Component blok={blok} meta={meta} />
      </SbEditable>
    )
  }

  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
    Sentry.captureMessage(
      `WARNING: Storyblock component ${blok.component} used in production with no mappable component!`,
    )
  }

  return process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production' ? (
    <p className="text-xl text-action-danger">
      The component <strong>{blok.component}</strong> has not been created in the app yet!
    </p>
  ) : null
}
