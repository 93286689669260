/* eslint-disable no-underscore-dangle */
import {
  TopNavigation,
  Footer,
  SocialSharing,
  PostDate,
  ProgressBar,
} from '@electro/consumersite/src/components'
import { Head, Typography } from '@electro/shared-ui-components'
import { DynamicComponent } from '@electro/consumersite/src/storyblok'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import getStoryBlokComponent from '@electro/consumersite/src/storyblok/helpers/getStoryBlokComponent'

import { PostStoryblok } from '@electro/consumersite/types/generated/storyblok'
import useTranslation from 'next-translate/useTranslation'
import { FormattedPost } from '../../helpers/formatPostsForGallery'
import { PostGallery } from '../PostGallery'

interface PostLayoutProps {
  blok: PostStoryblok
  relatedArticles?: FormattedPost[]
  meta: {
    lastPublished: string
    first_published_at: string
  }
  canonicalUrl?: string
}

export const PostLayout = ({ blok, meta, canonicalUrl, relatedArticles }: PostLayoutProps) => {
  const featuredImageUrl = getCmsAssetUrl(
    getStoryBlokComponent(blok.body, 'featureImage')?.image?.filename,
  )
  const { t } = useTranslation('common')

  const showLastPublishedDate =
    meta.lastPublished && meta.lastPublished !== meta?.first_published_at

  return (
    <>
      <Head
        title={blok.pageTitle}
        description={blok.pageDescription}
        image={`${featuredImageUrl}/m/1200x0`}
      >
        {canonicalUrl ? <link rel="canonical" href={canonicalUrl} /> : null}
        <script
          type="application/ld+json"
          /**
           * Disabling eslint rule for no-danger because the JSON is parsed at build time
           * this makes it less likely to cause a security issue by injecting malicious code
           * using xss attacks.
           */
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Article',
              headline: blok.pageTitle,
              description: blok.teaserText,
              image: featuredImageUrl || '',
              datePublished: meta?.first_published_at,
              dateModified: meta?.lastPublished,
            }),
          }}
        />
      </Head>
      <div className="relative bg-white pt-20 overflow-hidden postPage">
        <div className="absolute top-0 w-full h-80 sm:h-104 lg:h-128 bg-base bg-gradient-mesh" />
        <div className="flex flex-col justify-between min-h-[calc(100vh-theme(spacing.20))] ">
          <TopNavigation />
          <main
            className="flex-grow relative px-4 xl:px-0 override-dark-text override-list-style font-sans"
            data-testid="page-template-main"
          >
            <ProgressBar hasIcon className="top-16" />
            <div className="flex items-baseline mt-6 max-w-6xl mx-auto">
              <PostDate date={meta?.first_published_at} className="pl-2  text-white inline-block" />

              {showLastPublishedDate ? (
                <>
                  <Typography as="span" className="inline-block text-xs">
                    <span className="mx-3">|</span>
                    {t('blog.post.last_updated')}:
                  </Typography>
                  <PostDate date={meta?.lastPublished} className="pl-1  text-white inline-block" />
                </>
              ) : null}
            </div>
            {blok.body
              ? blok.body.map((blk) => <DynamicComponent blok={blk} key={blk._uid} />)
              : null}
            {!blok.hideSocialSharing && <SocialSharing pageDescription={blok.pageDescription} />}
          </main>
          {relatedArticles?.length > 0 ? (
            <div className="bg-gradient-mesh pb-16 mb-16  ">
              <Typography variant="h2" className="text-center py-12 -mb-8">
                {t('community.related_articles_heading')}
              </Typography>
              {/* Override the default post gallery style */}
              <div className="[&_h3]:text-white [&_div]:text-white/60 px-8">
                <PostGallery showConstantine posts={relatedArticles} />
              </div>
            </div>
          ) : null}
          <Footer />
        </div>
      </div>
    </>
  )
}
