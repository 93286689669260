/* eslint-disable no-underscore-dangle */
// 2 & 3 column responsive layout with grid

import { tw } from '@electro/shared/utils/tailwind-merge'
import { DynamicComponent } from '@electro/consumersite/src/storyblok'

const styles = {
  root: 'grid m-auto mb-16 mt-12 relative z-10 px-4',
  width: {
    screen: 'w-screen px-0',
    full: 'w-full',
    half: 'w-1/2',
    '6xl': 'max-w-6xl',
    '5xl': 'max-w-5xl',
    '4xl': 'max-w-4xl',
    '3xl': 'max-w-3xl',
    '2xl': 'max-w-2xl',
  },
  gap: {
    2: `gap-2`,
    4: `gap-4`,
    8: `gap-8`,
    12: `gap-12`,
    16: `gap-16`,
    32: `gap-12 md:gap-20 xl:gap-32`,
    64: `gap-16 md:gap-32 xl:gap-64`,
  },
  oneCol: 'grid-cols-1 ',
  twoCol: 'grid-cols-1 sm:grid-cols-2 ',
  threeCol: 'grid-cols-1 sm:grid-cols-3 ',
  fourCol: 'grid-cols-1 xs:grid-cols-2 sm:grid-cols-4',
  centered: 'items-center',
  centerText: 'text-center',
}

export const ColumnLayout = ({ blok }) => {
  const width = blok.width || 'full'

  return (
    <div
      className={tw({
        [styles.root]: true,
        [styles.oneCol]: blok.numColumns === '1',
        [styles.twoCol]: blok.numColumns === '2',
        [styles.threeCol]: blok.numColumns === '3',
        [styles.fourCol]: blok.numColumns === '4',
        [styles.centered]: blok.centered,
        [styles.centerText]: blok.centerText,
        [styles.gap[blok.gap]]: true,
        [styles.width[width]]: true,
      })}
    >
      {blok.content.map((innerBlok, index) =>
        index + 1 <= parseInt(blok.numColumns, 10) ? (
          <DynamicComponent key={innerBlok._uid} blok={innerBlok} />
        ) : null,
      )}
    </div>
  )
}
